import { LINKS } from '@utils/constants';
import { IEvent } from '@utils/types';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { formatDateRange } from '@utils/helpers';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function EventTemplate({
	data,
	pageContext: {
		breadcrumb: { location, crumbs },
	},
}: {
	data: {
		site: { siteMetadata: { siteUrl: string } };
		markdownRemark: {
			frontmatter: IEvent;
			html: string;
		};
		banner: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
	};
	pageContext: {
		breadcrumb: {
			location: string;
			crumbs: Breadcrumb[]
		};
	};
}) {
	const { t, i18n } = useTranslation();

	const { markdownRemark, banner, site } = data;
	if (!markdownRemark) return null;

	const { frontmatter, html } = markdownRemark;

	const cover = getImage(frontmatter.pageImage.childImageSharp.gatsbyImageData);
	const self = site.siteMetadata.siteUrl + location;

	const eventRegistrationLink = `${LINKS.ABOUT_EVENT_REGISTRATION}?event=${encodeURIComponent(frontmatter.event)}`;

	return (
		<Page>
			<SEO
				title={`${frontmatter.title} | ${t("_Events")} | Realis Simulation`}
				description=""
			/>

			<Banner
				title={frontmatter.title}
				image={{
					src: banner.childImageSharp.gatsbyImageData,
					alt: frontmatter.title,
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection layout="wide">
				<div className="event__wrapper">
					<div className="col xl3 offset-xl1 m4 offset-m0 s12 s-spacer event__aside">
						{cover && (
							<div className="resource__cover">
								<GatsbyImage
									image={cover}
									alt={frontmatter.title}
								/>
							</div>
						)}
						{(frontmatter?.programme ) && (
							<div className="event__button">
								<Button to={frontmatter?.programme} type="download">
									{t("_EventProgramme")}
								</Button>
							</div>)}
						{(frontmatter.register || frontmatter.register === "true") &&
							<div className="event__button">
								<Button to={eventRegistrationLink} type="more" >
									{t("_RegisterForEvent")}
								</Button>
							</div>}
						<div className="event__button">
							<Button to={LINKS.ABOUT_EVENTS} type="more">
								{t("_OtherEvents")}
							</Button>
						</div>
					</div>
					<div className="col xl6 offset-xl1 l7 offset-l0 m8 s12 s-spacer event__content">
						<header className="event__header">
							<h1 className="event__title">{frontmatter.title}</h1>
							<span className="event__location">
								<a href={frontmatter.eventMap}>{frontmatter.eventLocation}</a>
							</span>
							{frontmatter.date && <span className="event__date">
								{formatDateRange(frontmatter.date, frontmatter.endDate, i18n.resolvedLanguage)}
							</span>}
						</header>
						<div className="event__content"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
						<aside className="share__icons">
							<span>{t("_ShareThisEvent")}</span>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://www.linkedin.com/shareArticle?mini=true&url=${self}&title=${frontmatter.title}&summary=${frontmatter.eventLocation}`}
								className="social-icon__item social-icon--linkedin"></a>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://twitter.com/share?url=${self}&title=${frontmatter.title}`}
								className="social-icon__item social-icon--twitter"></a>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://www.facebook.com/sharer/sharer.php?u=${self}`}
								className="social-icon__item social-icon--facebook"></a>
						</aside>
					</div>
				</div>
			</PageSection>
		</Page>
	);
}

export const pageQuery = graphql`
	query ($language: String!, $slug: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		locales: allLocale(
			filter: { ns: { in: ["_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(
      		fields: { language: { eq: $language }, slug: { eq: $slug } }
		) {
			frontmatter {
				title
				event
				eventLocation
				eventMap
				date(formatString: "DD MMMM YYYY")
				endDate(formatString: "DD MMMM YYYY")
				pageImage {
					...imageStandard
				}
				programme
				register
			}
			html
		}

		banner: file(relativePath: { eq: "content/about-us/events/Realis-Simulation_Events_cropped.jpg" }) {
			...imageBreaker
		}
	}
`;